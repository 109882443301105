import { Injectable } from '@angular/core'
import { Observable, firstValueFrom } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { AngularFireDatabase } from '@angular/fire/compat/database'

import { DbPathsProvider } from '../db-paths/db-paths.service'
import { DeviceProvider } from '../device/device.service'
import { AuthProvider } from '../auth/auth.service'
import { EnvironmentProvider } from '../environment/environment.service'
import { FirebaseProvider } from '../firebase/firebase.service'

@Injectable({
  providedIn: 'root',
})
export class NotificationsProvider {
  private deviceIds: string[] = []
  public notify$: Observable<any>

  private tokenRefreshSubscribed: boolean = false

  constructor(
    private fb: FirebaseProvider,
    private paths: DbPathsProvider,
    private db: AngularFireDatabase,
    private device: DeviceProvider,
    private environment: EnvironmentProvider,
    private auth: AuthProvider
  ) {
    this.notify$ = this.fb.onNotificationOpen()

    // Always subscribe to token refresh events
    if (!this.tokenRefreshSubscribed) {
      this.tokenRefreshSubscribed = true
      this.fb.onTokenRefresh().subscribe(async () => {
        await this.publishTokens(this.deviceIds)
      })
    }
  }

  public async registerAppDevice(deviceIds: string[]): Promise<void> {
    if (this.environment.isNativeApp() && !this.auth.isDemoAccount()) {
      this.deviceIds = deviceIds

      if (this.environment.isIOSApp()) {
        await this.fb.grantPermission()
        await this.publishTokens(this.deviceIds)
      } else {
        // For Android or other platforms, publish tokens directly
        await this.publishTokens(this.deviceIds)
      }
    }
  }

  private async publishTokens(deviceIds: string[]) {
    try {
      if (!deviceIds || deviceIds.length === 0) {
        console.warn('No device IDs provided for token registration.')
        return
      }

      const deviceToken: string = await this.fb.getToken()
      if (!deviceToken) {
        console.error('Failed to retrieve FCM token.')
        return
      }

      const uid = await this.auth.currentUserUID()
      if (!uid) {
        console.error('User is not authenticated.')
        return
      }

      const registrations: Promise<void>[] = deviceIds.map((id) => {
        return this.registerAppDeviceWithUnit(id, uid, deviceToken)
      })
      await Promise.all(registrations)
    } catch (err) {
      console.error('Error publishing tokens:', err)
    }
  }

  private async registerAppDeviceWithUnit(
    deviceId: string,
    uid: string,
    token: string
  ): Promise<void> {
    try {
      const ref = this.db.list<string>(
        this.paths.notificationTokens(deviceId, uid)
      )

      const tokens: string[] = await firstValueFrom(
        ref.valueChanges().pipe(takeUntil(this.device.deviceUnselected$))
      )

      if (!tokens.includes(token)) {
        await ref.push(token)
      }
    } catch (err) {
      console.error(
        `Error registering device token for deviceId ${deviceId}:`,
        err
      )
    }
  }
}
