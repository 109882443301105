import { Injectable } from '@angular/core'
import { Platform } from '@ionic/angular'
import { ENV } from '@app/env'

export const AppVersion = '8.4.2'

const devUrl = 'https://app-dev.brnkl.io'
const stagingUrl = 'https://app-staging.brnkl.io'
const productionUrl = 'https://app.brnkl.io'

@Injectable({
  providedIn: 'root',
})
export class EnvironmentProvider {
  constructor(private platform: Platform) {}

  public isNativeApp() {
    return (
      !this.isWebApp() &&
      (this.platform.is('ios') ||
        this.platform.is('android') ||
        this.platform.is('cordova'))
    )
  }

  public isMobileDevice() {
    return (
      this.platform.is('ios') ||
      this.platform.is('mobileweb') ||
      this.platform.is('android') ||
      this.platform.is('cordova')
    )
  }

  public isIOSApp() {
    return !this.isWebApp() && this.platform.is('ios')
  }

  public isAndroidApp() {
    return !this.isWebApp() && this.platform.is('android')
  }

  public isWebApp() {
    try {
      if (ENV.IS_PROD) {
        return document.URL.includes(productionUrl)
      } else {
        return (
          document.URL.includes(devUrl) || document.URL.includes(stagingUrl)
        )
      }
    } catch (err) {
      return false
    }
  }

  get webUrl() {
    // Assume to always go to staging website if not production
    return ENV.IS_PROD ? productionUrl : stagingUrl
  }
}
