import { Injectable, Pipe, PipeTransform } from '@angular/core'
import { convertUnit } from '../../util'

@Pipe({
  name: 'convertValue',
})
@Injectable()
export class ConvertValuePipe implements PipeTransform {
  private defaultNDecimals: number = 5
  constructor() {}

  public transform(value: string, ...args): string {
    const nDecimals = args[2] || this.defaultNDecimals
    if (value && args[0] && args[1]) {
      const lowerUnit: string = args[0]
      const desiredUnit: string = args[1]
      return convertUnit(+value, lowerUnit)
        .to(desiredUnit)
        .toFixed(nDecimals)
    } else {
      const numVal: number = +value
      return numVal ? numVal.toFixed(nDecimals) : value
    }
  }
}
